<template>
	<div class="SamplesMainDiv">
		<el-card class="box-card">
			<el-row>
				<span class="sampleTitle">Sample List</span>
				<span>-All your sample orders will be displayed here-</span>
				<p class="hr"></p>
			</el-row>
			
			<el-form :inline="true" size="medium">
				<el-row>
					
					<el-col :span="8">
						<el-form-item label="Description" label-width="125px">
							<el-input placeholder="Pls input sample name." v-model="smplName" clearable> </el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item>
							<template #label>
								<span id="hhh">Status</span>
							</template>
							<el-select class="vd_smpl_select" v-model="status" placeholder="Select status of the order." clearable
							           filterable>
								<el-option class="status_select" v-for="item in statusList" :key="item.value" :label="item.label"
								           :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					
					<el-col :span="8">
						<el-form-item label="Date" label-width="125px">
							<el-date-picker v-model="dateValue" type="daterange" range-separator="To" start-placeholder="Start Date"
							                unlink-panels class="vd_smpl_select" end-placeholder="End Date">
							</el-date-picker>
						</el-form-item>
					</el-col>
				
				</el-row>
				
				<el-row>
					<el-col :span="8">
						<el-form-item label="Theme" label-width="125px">
							<el-input placeholder="Pls input sample theme." v-model="smplTheme" clearable> </el-input>
						</el-form-item>
					</el-col>
					
					<el-col :span="8">
						<el-form-item>
							<template #label>
								<span id="test">Account Manager</span>
							</template>
							<el-select class="vd_smpl_select" v-model="stffName" placeholder="Select the account manager." clearable
							           filterable>
								<el-option class="status_select" v-for="item in stffList" :key="item.value" :label="item.label"
								           :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="2">&nbsp;</el-col>
				</el-row>
			</el-form>
			<div style="padding-left: 2.48vw;">
				<el-button type="primary" class="searchButton" @click="searchSmpl()" size="medium">Search</el-button>
				<el-button type="info" class="resetButton" @click="resetSearch()" size="medium">Reset</el-button>
			</div>
			
			<el-row class="tipRow">
				<span>Tips:</span>&nbsp;
				<i class="el-icon-remove-outline noNeedIcon">&nbsp;</i>
				<span>means this process is no need.</span>
				<i class="el-icon-circle-check notYetIcon">&nbsp;</i>
				<span>means this process hasn't started yet.</span>
				<i class="el-icon-circle-check ingIcon">&nbsp;</i>
				<span>means this process is in progress.</span>
				<i class="el-icon-circle-check finishIcon">&nbsp;</i>
				<span>means this process is complete.</span>
			</el-row>
			
			<el-row>
				<el-col :span="24">
					<el-table class="smplsTable" :data="tableData" style="width: 100%;" border>
						<el-table-column label="Process Single" width="200px" align="center" show-overflow-tooltip>
							<template v-slot="scope">
								<el-row>
									<!--<imgPopover v-if="scope.row.craft_imge_url" :imgsrc="scope.row.craft_imge_url"  :imgType="1"></imgPopover>-->
									<el-image
											lazy
											fit="scale-down"
											style="width: 175px; height: 175px"
											@mouseover="addImageSrcList(scope.row.craft_imge_url, '')"
											:src="formatPic3(scope.row.craft_imge_url, '')"
											:preview-src-list="imageSrcList">
										<div slot="error" class="image-slot">
											<span class="failedImgSpan">No Image</span>
										</div>
									</el-image>
								</el-row>
							</template>
						</el-table-column>
						<el-table-column label="Sample" width="350px" align="left" show-overflow-tooltip>
							<template v-slot="scope">
								<el-row class="picAndSmplInfo">
									<el-col :span="8" style="display: flex">
										<el-row class="vd_img_row">
											<el-image
													lazy
													style="width: 100px; height: 100px"
													@mouseover="addImageSrcList(scope.row.imge_url, 'l')"
													:src="formatPic3(scope.row.imge_url, 'l')"
													:preview-src-list="imageSrcList">
												<div slot="error" class="image-slot">
													<span class="failedImgSpan">No Image</span>
												</div>
											</el-image>
										</el-row>
									</el-col>
									<el-col :span="16" class="smplsInfoDiv" align="left">
										<el-row class="three_points">
											<span class="spanPrefix">Desc &nbsp;</span>
											<span v-if="scope.row.smpl_name_en == null" class="noData">No Data</span>
											<span class="vd_smpl_name_en" v-else>{{ scope.row.smpl_name_en }}</span>
										</el-row>
										<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Theme &nbsp;</span>
                        <span v-if="scope.row.smpl_theme != null">{{ scope.row.smpl_theme }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
										</el-row>
										<el-row class="vd_smpl_span">
											<span><span class="spanPrefix">No.&nbsp;</span>{{ scope.row.smpl_no }}</span>
										</el-row>
										<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Account Manager &nbsp;</span>
                        <span v-if="scope.row.staff_name != null">{{ scope.row.staff_name }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
										</el-row>
										<el-row class="vd_smpl_span">
                      <span><span class="spanPrefix">Create Date&nbsp;</span>{{
		                      formatDate(scope.row.create_time)
	                      }}</span>
										</el-row>
										<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Sample Quantity &nbsp;</span>
                        <span v-if="scope.row.smpl_cust_num != null">{{ scope.row.smpl_cust_num }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
										</el-row>
									</el-col>
<!--									<el-popover
											placement="top-start"
											width="350"
											trigger="hover">
										<el-row class="picAndSmplInfo">
											<el-col :span="24" class="smplsInfoDiv">
												<el-row class="three_points">
													<span class="spanPrefix">Desc &nbsp;</span>
													<span v-if="scope.row.smpl_name_en == null" class="noData">No Data</span>
													<span class="vd_smpl_name_en" v-else>{{ scope.row.smpl_name_en }}</span>
												</el-row>
												<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Theme &nbsp;</span>
                        <span v-if="scope.row.smpl_theme != null">{{ scope.row.smpl_theme }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
												</el-row>
												<el-row class="vd_smpl_span">
													<span><span class="spanPrefix">No.&nbsp;</span>{{ scope.row.smpl_no }}</span>
												</el-row>
												<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Account Manager &nbsp;</span>
                        <span v-if="scope.row.staff_name != null">{{ scope.row.staff_name }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
												</el-row>
												<el-row class="vd_smpl_span">
													<span class="spanPrefix">Create Date&nbsp;</span>
													{{formatDate(scope.row.create_time)}}
												</el-row>
												<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Sample Quantity &nbsp;</span>
                        <span v-if="scope.row.smpl_cust_num != null">{{ scope.row.smpl_cust_num }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
												</el-row>
											</el-col>
										</el-row>
										<el-row slot="reference" class="picAndSmplInfo" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
											<el-col :span="24" class="smplsInfoDiv">
												<el-row class="three_points">
													<span class="spanPrefix">Desc &nbsp;</span>
													<span v-if="scope.row.smpl_name_en == null" class="noData">No Data</span>
													<span class="vd_smpl_name_en" v-else>{{ scope.row.smpl_name_en }}</span>
												</el-row>
												<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Theme &nbsp;</span>
                        <span v-if="scope.row.smpl_theme != null">{{ scope.row.smpl_theme }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
												</el-row>
												<el-row class="vd_smpl_span">
													<span><span class="spanPrefix">No.&nbsp;</span>{{ scope.row.smpl_no }}</span>
												</el-row>
												<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Account Manager &nbsp;</span>
                        <span v-if="scope.row.staff_name != null">{{ scope.row.staff_name }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
												</el-row>
												<el-row class="vd_smpl_span">
													<span class="spanPrefix">Create Date&nbsp;</span>
													{{formatDate(scope.row.create_time)}}
												</el-row>
												<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Sample Quantity &nbsp;</span>
                        <span v-if="scope.row.smpl_cust_num != null">{{ scope.row.smpl_cust_num }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
												</el-row>
											</el-col>
										</el-row>
									</el-popover>-->
								</el-row>
							</template>
						</el-table-column>
						
						
						<!-- 分割线，下面是工序步骤内容 -->
						
						<el-table-column align="center" label="Step">
							<template v-slot="scope">
								<el-row>
									<el-col style="width: 8.9819%">
										<el-row class="three_points">
											<el-popover placement="top-start" width="150px"  trigger="hover"
											            content="Pattern Making">
												<el-row slot="reference">Pattern</el-row>
												<el-row slot="reference">Making</el-row>
											</el-popover>
										</el-row>
										<el-row class="iconRow">
											<i :class="addIconClass(scope.row.open_status)"></i>
										</el-row>
									</el-col>
									<el-col style="width: 8.9819%">
										<el-row class="three_points">
											<el-popover placement="top-start" width="150px"  trigger="hover"
											            content="Material Preparing">
												<el-row slot="reference">Material</el-row>
												<el-row slot="reference">Preparing</el-row>
											</el-popover>
										</el-row>
										<el-row class="iconRow">
											<i :class="addIconClass(scope.row.stpr_status)"></i>
										</el-row>
									</el-col>
									<el-col style="width: 8.9819%">
										<el-row class="three_points">
											<el-popover placement="top-start" width="150px"  trigger="hover"
											            content="Lining">
												<el-row slot="reference" >Lining</el-row>
											</el-popover>
										</el-row>
										<el-row>&nbsp;</el-row>
										<el-row class="iconRow">
											<i :class="addIconClass(scope.row.comp_status)"></i>
										</el-row>
									</el-col>
									<el-col style="width: 8.9819%">
										<el-row class="three_points">
											<el-popover placement="top-start" width="150px"  trigger="hover"
											            content="Cutting">
												<el-row slot="reference">Cutting</el-row>
											</el-popover>
										</el-row>
										<el-row>&nbsp;</el-row>
										<el-row class="iconRow">
											<i :class="addIconClass(scope.row.lase_status)"></i>
										</el-row>
									</el-col>
									<el-col style="width: 8.9819%">
										<el-row class="three_points">
											<el-popover placement="top-start" width="150px"  trigger="hover"
											            content="Printing">
												<el-row slot="reference">Printing</el-row>
											</el-popover>
										</el-row>
										<el-row>&nbsp;</el-row>
										<el-row class="iconRow">
											<i :class="addIconClass(scope.row.stam_status)"></i>
										</el-row>
									</el-col>
									<el-col style="width: 8.9819%">
										<el-row class="three_points">
											<el-popover placement="top-start" width="150px"  trigger="hover"
											            content="Embroidery">
												<el-row slot="reference">Embroidery</el-row>
											</el-popover>
										</el-row>
										<el-row>&nbsp;</el-row>
										<el-row class="iconRow">
											<i :class="addIconClass(scope.row.embr_status)"></i>
										</el-row>
									</el-col>
									<!--                  <el-col :span="2">
																			<el-row class="three_points">
																				<el-popover placement="top-start" width="150px"  trigger="hover"
																					content="Embroidery Pattern Making">
																					<el-row slot="reference">Embroidery</el-row>
																					<el-row slot="reference">Pattern</el-row>
																				</el-popover>
																			</el-row>
																			<el-row class="iconRow">
																				<i :class="addIconClass(scope.row.emcd_status)"></i>
																			</el-row>
																		</el-col>-->
									<el-col style="width: 8.9819%">
										<el-row class="three_points">
											<el-popover placement="top-start" width="150px"  trigger="hover"
											            content="Machine Sewing">
												<el-row slot="reference">Machine </el-row>
												<el-row slot="reference">Sewing</el-row>
											</el-popover>
										</el-row>
										<el-row class="iconRow">
											<i :class="addIconClass(scope.row.mase_status)"></i>
										</el-row>
									</el-col>
									<el-col style="width: 8.9819%">
										<el-row class="three_points">
											<el-popover placement="top-start" width="150px"  trigger="hover"
											            content="Hand Sewing">
												<el-row slot="reference">Hand</el-row>
												<el-row slot="reference">Sewing</el-row>
											</el-popover>
										</el-row>
										<el-row class="iconRow">
											<i :class="addIconClass(scope.row.manu_status)"></i>
										</el-row>
									</el-col>
									<el-col style="width: 8.9819%">
										<el-row class="three_points">
											<el-popover placement="top-start" width="150px"  trigger="hover"
											            content="Sample Checking">
												<el-row slot="reference">Sample</el-row>
												<el-row slot="reference">Checking</el-row>
											</el-popover>
										</el-row>
										<el-row class="iconRow">
											<i :class="addIconClass(scope.row.insp_status)"></i>
										</el-row>
									</el-col>
									<el-col style="width: 8.9819%">
										<el-row class="three_points">
											<el-popover placement="top-start" width="150px"  trigger="hover"
											            content="Sample Finished">
												<el-row slot="reference">Sample</el-row>
												<el-row slot="reference">Finished</el-row>
											</el-popover>
										</el-row>
										<el-row class="iconRow">
											<i v-if="scope.row.inspect == 1" class="el-icon-circle-check finishIcon"></i>
											<i v-else class="el-icon-circle-check notYetIcon"></i>
										</el-row>
									</el-col>
									<el-col style="width: 8.9819%">
										<el-row class="three_points">
											<el-popover placement="top-start" width="150px"  trigger="hover"
											            content="Ship">
												<el-row slot="reference">Ship</el-row>
											</el-popover>
										</el-row>
										<el-row>&nbsp;</el-row>
										<el-row class="iconRow">
											<i v-if="scope.row.post == '1'" class="el-icon-circle-check finishIcon"></i>
											<i v-else class="el-icon-circle-check notYetIcon"></i>
										</el-row>
									</el-col>
								</el-row>
							</template>
						</el-table-column>
					
					
					
					
					</el-table>
				</el-col>
			</el-row>
			
			<el-row class="pageRow">
				<pubPagination :totalPage='totalItem' @changePageSearch="changePageSearch" ref="pubPagination">
				</pubPagination>
			</el-row>
		
		
		</el-card>
	</div>
</template>

<script>

import { get } from "@/api/request";
import { dyjAPI } from "@/api/modules/dyj";
import pubPagination from "@/components/common/pubPagination";
import imgPopover from "@/components/imgPopover.vue"

export default {
	name: 'myMenu',
	components: {
		pubPagination,
		imgPopover
	},
	watch:{
		$route(to,from){
			if(from.path === '/home'){
				this.smplNo=this.$route.query.smplNo
				this.$router.push({ query: {} });
				this.initData()
			}
		},
	},
	mounted () {
		document.getElementsByClassName("el-form-item__label")[1].setAttribute("style",`width:${document.getElementsByClassName("el-form-item__label")[4].offsetWidth}px`)
		window.addEventListener('resize', (event) => {
			document.getElementsByClassName("el-form-item__label")[1].setAttribute("style",`width:${document.getElementsByClassName("el-form-item__label")[4].offsetWidth}px`)
		})
	},
	methods: {
		addImageSrcList(row, type) {
			if (!row) return;
			this.imageSrcList[0] = this.helper.picUrl(row, type)
		},
		// 格式化图片
		formatPic3(row, type) {
			if (!row) return;
			return this.helper.picUrl(row, type)
		},
		changeActive(val) {
			if (val) {
				this.leftVisible = true;
			} else {
				this.rightVisible = true;
			}
		},
		removeActive(val) {
			if (val) {
				this.leftVisible = false;
			} else {
				this.rightVisible = false;
			}
		},
		// 初始化方法
		initData() {
			this.getAllSmpls()
		},
		// 搜索按钮
		searchSmpl() {
			this.$refs.pubPagination.currentPage = 1;
			this.changePageSearch(1);
		},
		// 重置按钮
		resetSearch() {
			this.currentPage = 1,
					this.smplName = '',
					this.smplNo = '',
					this.status = '',
					this.dateValue = '',
					this.smplTheme='',
					this.stffName='',
					this.$refs.pubPagination.currentPage = 1;
			this.changePageSearch(1);
		},
		// 获取所有样单信息
		getAllSmpls() {
			const timeNewVal = this.helper.getTime(this.dateValue)
			get(dyjAPI.getAllSmpls, {
				smplTheme: this.smplTheme,
				stffName: this.stffName,
				pageNo: this.currentPage,
				smplName: this.smplName,
				smplNo: this.smplNo,
				status: this.status,
				startTime: timeNewVal.startTime,
				endTime: timeNewVal.endTime,
			})
					.then((res) => {
						// 请求成功时
						if (res.data.code === 0) {
							this.tableData = res.data.data.list;
							this.totalItem = res.data.data.totalItem;
							// 失败
						} else {
							let mg = res.data.msg;
							// let tp = 'error';
							this.$message({ message: mg });
						}
					})
					.catch(res => {
						let mg = res.data.msg;
						let tp = 'error';
						this.$message({ message: mg, type: tp });
					})
		},
		// 分页查询
		changePageSearch(val) {
			this.currentPage = val
			this.getAllSmpls()
		},
		// 搜索按钮
		searchMtrl() {
			this.$refs.pubPagination.currentPage = 1;
			this.changePageSearch(1);
		},
		// 格式化图片
		formatPic(row) {
			return this.helper.picUrl(row.imge_url, 'l')
		},
		// 格式化图片
		formatPic2(url) {
			return this.helper.picUrl(url, '')
		},
		// 时间转换
		formatDate(row) {
			return this.helper.toStringDate(row)
		},
		// 动态改变图标
		addIconClass(statsID) {
			if (statsID == 1) {
				return "el-icon-circle-check notYetIcon"
			} else if (statsID == 0) {
				return "el-icon-remove-outline noNeedIcon"
			} else if (statsID == 2) {
				return "el-icon-circle-check ingIcon"
			} else {
				return "el-icon-circle-check finishIcon"
			}
		}
	},
	created() {
		if (this.$route.query.smplNo) {
			this.smplNo = this.$route.query.smplNo;
			this.$router.push({query: {}});
		}
		this.initData();
	},
	data() {
		return {
			imageSrcList: [""],
			leftVisible: false,
			rightVisible: false,
			// 获取所有样单信息的数组
			tableData: [{
			}],
			// 订单日期
			dateValue: {
				startTime: null,
				endTime: null
			},
			stffName:'',
			// 样品编号
			smplNo: '',
			// 主题
			smplTheme:'',
			// 样品名
			smplName: '',
			// 状态值
			status: '',
			// 状态下拉列表
			statusList: [{
				value: '0',
				label: 'In Progress'
			},
				{
					value: '1',
					label: 'Completed'
				}],
			// 外销人员下拉列表
			stffList:[{
				value: 'Ethan',
				label: 'Ethan',
			},
				{
					value:'Yvonne',
					label:'Yvonne',
				},
				{
					value:'Roger',
					label:'Roger',
				},
				{
					value:'Ken',
					label:'Ken',
				},
				{
					value:'Jerry',
					label:'Jerry',
				},
				{
					value:'Bruce',
					label:'Bruce',
				},
				{
					value:'Bryan',
					label:"Bryan",
				},
				{
					value:'Jennifer',
					label:'Jennifer',
				},
				{
					value:'Sally',
					label:'Sally',
				},
				{
					value:'Mary',
					label:'Mary',
				},
				{
					value:'Ronnie',
					label:"Ronnie",
				},
				{
					value:'Grace',
					label:'Grace',
				},
				{
					value:'Heidi',
					label:'Heidi',
				},
				{
					value:'Wayne',
					label:'Wayne',
				},
				{
					value:'Joy',
					label:'Joy',
				},
				{
					value:'Diego',
					label:'Diego',
				},
				{
					value:'Amber',
					label:'Amber',
				}],
			// 分页数据总数
			totalItem: 0,
			// 当前页数,默认1
			currentPage: 1,
		}
	},
}
</script>
<style >
.clearfix:before,
.clearfix:after {
	display: table;
	content: "";
}

.clearfix:after {
	clear: both
}

.box-card {
	height: 100%;
	margin: 20px 40px 0 230px;
	padding-bottom: 100px;
}

/* sampleList标题 */
.sampleTitle {
	font-size: x-large;
	color: rgb(110, 110, 110);
	float: left;
	font-weight: bolder;
	margin: 10px 10px 30px 10px;
}

/* sampleList标题后的说明 */
.sampleTitle+span {
	color: grey;
	float: left;
	font-size: 14px;
	margin: 18px 10px 20px 10px;
}

/* 分割线 */
.SamplesMainDiv .hr {
	border-bottom: 1px solid grey;
	margin-top: 55px;
}

/* 改变el时间选择器自带样式 */
.SamplesMainDiv .el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
	width: 250px !important;
}

/* 搜索按钮 */
.SamplesMainDiv .searchButton {
	font-size: 12px;
	margin-right: 20px;
}

/* 重置按钮 */
.SamplesMainDiv .resetButton {
	font-size: 12px;
}

/* 样品表 */
.smplsTable {
	margin-top: 20px;
}

/* 改变el-table表头边框 */
/* .SamplesMainDiv th.el-table_2_column_2 {
  border-right: none;
}

/* 物料信息和图片所在的row */
.picAndSmplInfo {
	/* border-right: 1px solid rgba(112, 112, 112, 0.1); */
	padding: 6px 6px 6px 2px;
}

/*  hover图片时放大 */
.SamplesMainDiv .vd_popimg {
	width: 400px;
	height: 400px;
}

/* 分页行 */
.SamplesMainDiv .pageRow {
	margin-top: 20px;
	padding-left: 40%;
}

/* 样品英文名 */
.vd_smpl_name_en {
	font-size: 15px;
	
}

/* 样品英文名下的各种信息 */
.vd_smpl_span {
	margin-top: 6px;
}

/* 各种信息前缀 */
.spanPrefix {
	font-weight: bold;
}


/* 所有勾图标的大小 */
.smplsTable .el-icon-circle-check {
	font-size: 26px;
}

/* 所有杠图标的大小 */
.smplsTable .el-icon-remove-outline {
	font-size: 26px;
}

/* 图标行 */
.iconRow {
	margin-top: 15px;
	margin-bottom: 10px;
}

/* 经历中图标 */
.ingIcon {
	color: rgb(222, 203, 0);
}

/* 未经历图标 */
.notYetIcon {
	color: #cacacaa6;
}

.noNeedIcon {
	color: #423d3da6;
}

/* 已完成图标 */
.SamplesMainDiv .finishIcon {
	color: #5eca32;
}

/* 提示信息行 */
.SamplesMainDiv .tipRow {
	margin-top: 30px;
}

/* 提示信息里的文字 */
.SamplesMainDiv .tipRow span {
	color: grey;
	float: left;
	margin-right: 10px;
	font-size: 15px;
}

/* 提示信息里的图标 */
.SamplesMainDiv .tipRow i {
	font-size: 21px;
	float: left;
}

/* 加载失败时的占位图片 */
.SamplesMainDiv .failedImg {
	width: 100px;
	height: 100px;
	border: 1px solid rgba(112, 112, 112, 0.1);
}

.image-slot {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

/* 加载成功时的小图 */
.SamplesMainDiv .vd_elimg {
	width: 100px;
	height: 100px;
}
/* 图片所在的row */
.vd_img_row{
	padding-top: 20px;
}
/* 图片所在的row */
.vd_img_row{
	padding-top: 20px;
}
/* 样品图片和信息的间距 */
.SamplesMainDiv .smplsInfoDiv {
	padding-left: 16px;
}

/* 改变EL-lable自带样式 */
.SamplesMainDiv .el-form--inline .el-form-item__label {
	text-align: right;
}

/* 文字超出部分省略 */
.three_points {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.failedImg {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.failedImgSpan {
	color: #ccc;
}

.processSheetImage {
	width: 100%;
	height: 100%;
}
</style>